import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 310 250">
    <path
      fill="none"
      stroke="#f9bc60"
      strokeDasharray="none"
      strokeLinecap="round"
      strokeLinejoin="miter"
      strokeMiterlimit="4"
      strokeOpacity="1"
      strokeWidth="21.186"
      d="M166.951 28.062c-34.728 116.751-34.98 175.445-88.556 192.018-80.574 21.563-104.86-144.378 60.018-90.922 57.847 24.297 52.922 32.605 94.77 42.468C377.937 187.204 222-47.708 198.308 66.321l-23.674 102.103"></path>
  </svg>
);

export default IconLogo;
